import React from "react";
import Layout from "../components/Layout";
import Product from "../components/Products/Product";
import { turkeyProducts } from "../data/turkey/turkeyProduct";

const Turkey = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;
  return (
    <Layout title={turkeyProducts[i].recipeName} description="">
      <Product id={i} proteinProducts={turkeyProducts} />
    </Layout>
  );
};

export default Turkey;
